.notes {
    display: flex;
    flex-direction: column;
    min-height: 99vh;
    max-height: 100vh;
    height: 100%;
}

.open-note {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    background-color: var(--blue);
}

.arrow-box img {
    width: 50%;
    margin-left: 1.5rem;
    margin-right: 0rem;
    cursor: pointer;
}

.groupIcon {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    font-size: clamp(1.2rem, 4vw, 1.8rem);
    font-weight: 500;
}

.note-heading {
    font-size: clamp(1.2rem, 5vw, 2rem);
}

.main-content {
    display: flex;
    background-color: var(--desert);
    top: 10rem;
    flex-grow: 1;
    padding: 1rem;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;
}

.note {
    display: flex;
    gap: 1rem;
}

.note p {
    margin: 0.1rem;
}

.date {
    width: 35%;
    color: var(--slate-darker);
    font-weight: 500;
}

.text {
    width: 65%;
    font-weight: 400;
}

.write-note {
    margin-top: auto;
    min-height: 22vh;
    padding: 1.2rem;
    background-color: var(--blue);
    box-sizing: border-box;
    /* position: relative; */
}

.write-note input {
    /* margin-left: 1%; */
    /* padding-top: 1rem;
    padding-left: 0.6rem;
    padding-bottom: 8.5rem; */
    padding: 3.5rem 2rem;
    width: 100%;
    border-radius: 6px;
    border: 1px solid white;
    font-size: 1.1rem;
}

.write-note input::placeholder {
    /* padding-left: 1rem; */
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.12rem;
}

.send {
    position: absolute;
    right: 1.5rem;
    bottom: 1.2rem;
    cursor: pointer;
}

.send:hover {
    filter: invert(100%);
}

@media screen and (min-width: 750px) {
    .arrow-box img {
        display: none;
    }

    .notes {
        min-height: 98vh;
        max-height: 98vh;
        height: 98%;
    }

    .write-note {
        border-bottom-left-radius: 15px;
    }

    .write-note input {
        border-radius: 9px;
    }

    .send {
        right: 1.5rem;
        bottom: 2rem;
    }
}