.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    height: 100%;
    text-align: center;
    background-color: var(--desert);
}

.text-section {
    width: 60%;
}

.text-section h2 {
    font-size: 2.5rem;
    margin-bottom: 0.8rem;
    font-weight: 400;
}

.text-section p {
    font-size: 1.3rem;
}

.text-section p , .watermark {
    color: var(--slate-dark);
}

.watermark {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    bottom: 2rem;
}