
.all-notes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 74.5vh;
    overflow: auto;
}

.open-note {
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    padding-left: 1.5rem;
}

.open-note:hover {
    background-color: var(--desert);
}

.groupIcon {
    display: flex;
    color: white;
    min-width: 3.5rem;
    min-height: 3.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: clamp(1.2rem, 7vw, 2rem);
    font-weight: 500;
}

.note {
    font-size: clamp(1.2rem, 7vw, 2rem);
    font-weight: 500;
}

@media screen and (min-width: 750px) {

    .all-notes {
        padding-left: 1rem;
    }

    .open-note {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    .add-background {
        background-color: var(--desert);
    }

    .icon {
        font-size: clamp(1.2rem, 5vw, 1.8rem);
    }

    .note {
        font-size: clamp(1.2rem, 5vw, 1.8rem);
    }

}